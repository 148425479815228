const colors = {
  gray1: "rgba(253, 252, 252, 1)",
  gray2: "rgba(240, 240, 240, 1)",
  gray5: "rgba(232, 232, 232, 1)",
  gray60: "rgba(104, 104, 104, 1)",
  gray70: "rgba(80, 80, 80, 1)",
  gray80: "rgba(57, 56, 57, 1)",
  gray100: "rgba(9, 9, 10, 1)",
  purple70: "rgba(63, 38, 143, 1)",
  purple80: "rgba(47, 24, 123, 1)",
  purple90: "rgba(85, 36, 123, 1)",
  neutral100: "rgba(34, 43, 46, 1)",
  blue80: "rgba(17, 61, 148, 1)",
  blue90: "rgba(13, 51, 128, 1)",
  pink90: "rgba(104, 3, 151, 1)",
  green60: "rgba(5, 114, 55, 1)",
}

export default colors
